import React, { memo } from 'react'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Button from 'components/Button'
import useSiteMetadata from 'hooks/useSiteMetadata'
import loadable from '@loadable/component'
import objectsDiff from 'utils/objectsDiff'

const PartnerConditions = () => {
	const { partnerUrl } = useSiteMetadata()
	const registerUrl = partnerUrl + '/sign-up'
	const Text = loadable(() => import(`./components/Text`))
	return (
		<Layout title="Условия участия в партнерской программе">
			<SEO title="Условия участия в партнерской программе — «Парсик»" noindex />
			<Sector>
				<div className="static-text mb-xl">
					<Text />
				</div>
				<div className="text-center">
					<Button isLink to={registerUrl} title="Партнерская программа" size="lg" theme="default">
						Зарегистрироваться
					</Button>
				</div>
			</Sector>
		</Layout>
	)
}

// Мемоизация необходима для предотвращения бесконечного обновления компонента
// при использовании loadable внутри
export default memo(PartnerConditions, (prevProps, nextProps) => !objectsDiff(prevProps, nextProps))
